import { instance } from "@instance-config";
import { pushDataLayerEventLanding } from "@utils/datalayerEventLandingsPortugal";
import { pushDataLayerInfoLanding } from "@utils/datalayerInfoLandingsPortugal";
import { getWindow } from "ssr-window";

const isIPAM = instance.includes("IPAM");
const isIADE = instance.includes("IADE");
const isIADEES = instance.includes("iadeES");
const isCEG = instance.includes("CEG");

const pageTypes = {
	MultiproductHeroForm: "Multiproducto",
	ProductHeroForm: "Producto",
	EventHeroForm: "Evento",
};

const window = getWindow();

const getPageLanguage = (pageLanguageId) => {
	const siteLangs = [
		{ id: 1, name: "espanol" },
		{ id: 2, name: "ingles" },
		{ id: 3, name: "portugues" },
	];
	const pageLang = siteLangs.find((el) => el.id === pageLanguageId);
	return pageLang?.name || null;
};

function generateAutomaticDimensions(page) {
	const pageData = page?.pageContext?.page;

	/// ------------------- IPAM y IADE ------------------- ///
	const fullUrl = pageData?.fullUrl;
	const templateData = pageData?.template;
	const structuredDataContent = pageData?.structuredDataContent;
	const courseMode =
		structuredDataContent?.courseMode?.length > 0
			? structuredDataContent?.courseMode[0]?.label
			: "";

	const pageLanguage = getPageLanguage(pageData?.language);
	const academicType = templateData?.programType?.[0]?.title;
	const academicName = templateData?.programTitle;
	const academicModality =
		courseMode.toLowerCase().includes("presencial") ||
		courseMode.toLowerCase().includes("in-person")
			? "presencial"
			: courseMode.toLowerCase().includes("online")
			? "online"
			: "semipresencial";
	const academicECTs = templateData?.credits;
	const academicStart = templateData?.start;
	const academicProductID = templateData?.salesforceID;
	const academicArea = templateData?.knowledgeArea?.[0]?.title; // IADE only
	/// --------------------------------------------------- ///

	/// ------------------- IADE España ------------------- ///
	const academicRecognition =
		templateData?.academicRecognition === "official"
			? "official"
			: "non_official";
	const academicQualification =
		templateData?.academicRecognition === "official"
			? "colaboración_iade"
			: "iade";
	/// --------------------------------------------------- ///

	const dimensions = pageData?.dimensions?.values || {};
	const result = {
		event: "custom_event",
		event_name: "page_view",
	};

	const templateType = pageData?.template?.templateType;
	const titleTitulacion = pageData.template?.heroSection?.modules[0];

	const pageType = pageTypes[templateType] || "Otro";

	const getPageCategory = (url, sub = false) => {
		const doubleSlash = url.indexOf("//");
		const cutUrl = url.slice(doubleSlash + 2, url.length);
		const splitUrl = cutUrl.split("/");

		if (sub) {
			if (splitUrl[2]) {
				return splitUrl[2];
			}
			return splitUrl[1];
		}

		return splitUrl[1];
	};

	if (isCEG) {
		result.pageCategory = pageData?.isHome ? "home" : getPageCategory(fullUrl);
		result.pageSubCategory = pageData?.isHome
			? "home"
			: getPageCategory(fullUrl, true);
	}

	switch (templateType) {
		case "MultiproductHeroForm":
		case "ProductHeroForm":
		case "EventHeroForm":
			result.pageType = pageType;
			result.titulacion_nombre =
				titleTitulacion.title && titleTitulacion.title.content
					? titleTitulacion.title.content
					: "";

			result.titulacion_tipo_de_estudio =
				dimensions["titulacion_tipo_de_estudio"] || "todos";
			result.titulacion_campus = dimensions["titulacion_campus"] || "todos";
			break;
		case "BasicTemplate":
			if (isIADEES) {
				result.pageType = pageData?.isHome ? "home" : "contenido";
			}
			if (isIPAM || isIADE) {
				result.pageType = pageData?.isHome ? "home" : "conteudo";
			}
			if (isIPAM || isIADE || isIADEES) {
				result.pageLanguage = pageLanguage;
				result.URLlimpia = fullUrl;
			}
			if (isCEG) {
				result.pageType = pageData?.isHome ? "home" : "basic template";

				const modules = templateData?.mainSection?.modules;
				for (let i = 0; i < modules.length; i++) {
					if (modules[i].component === "StudyDistributor") {
						result.pageType = "study list";
						break;
					}
				}
			}
			break;
		case "EventDetail":
			if (isIPAM || isIADE || isIADEES) {
				result.pageType = "evento";
				result.pageLanguage = pageLanguage;
				result.URLlimpia = fullUrl;
			}
			if (isCEG) {
				result.pageType = "event";
			}
			break;
		case "EventList":
			if (isIPAM || isIADE || isIADEES) {
				result.pageType = "lista";
				result.pageLanguage = pageLanguage;
				result.URLlimpia = fullUrl;
			}
			if (isCEG) {
				result.pageType = "event list";
			}
			break;
		case "NewsDetail":
			if (isIPAM || isIADE || isIADEES) {
				result.pageType = "noticia";
				result.pageLanguage = pageLanguage;
				result.URLlimpia = fullUrl;
			}
			if (isCEG) {
				result.pageType = "news";
			}
			break;
		case "NewsList":
			if (isIADEES) {
				result.pageType = "distributiva noticia";
			}
			if (isIPAM || isIADE) {
				result.pageType = "distribuicao noticias";
			}
			if (isIPAM || isIADE || isIADEES) {
				result.pageLanguage = pageLanguage;
				result.URLlimpia = fullUrl;
			}
			if (isCEG) {
				result.pageType = "news list";
			}
			break;
		case "StudyDetail":
			if (isIADEES) {
				result.pageType = "ficha";
				result.academicCampus =
					dimensions?.academicCampus ||
					templateData?.courseMode.some((mode) => mode.code === "Online")
						? "online"
						: "creative campus";
				const areas = {
					"Diseño de interiores": "diseno de interiores",
					"Diseño de moda": "moda",
				};
				result.academicArea = templateData?.knowledgeArea
					?.map((area) => areas[area.title] || area.title)
					.join(", ");
				result.academicType = templateData?.programType
					?.map((type) => type.title)
					.join(", ");
				result.academicDuration =
					dimensions?.academicDuration ||
					`${templateData?.[templateData?.duration]} ${templateData?.duration}`;
				result.academicLanguage = dimensions?.academicLanguage || pageLanguage;
				result.academicSchool = dimensions?.academicSchool || academicModality;
			}
			if (isIPAM || isIADE) {
				result.pageType = "guia do programa";
				if (templateData?.campus !== undefined) {
					result.academicCampus = templateData.campus[0].title;
				}
				result.academicSchool = courseMode.toLowerCase().includes("online")
					? courseMode
					: templateData.location[0]?.title;
				result.academicType = academicType && academicType.toLowerCase();
				result.academicDuration = dimensions?.academicDuration;
				result.academicLanguage = dimensions?.academicLanguage;
			}
			if (isIPAM || isIADE || isIADEES) {
				const academicHierarchy = `${academicType}_${academicModality}_${academicProductID}`;
				result.pageLanguage = pageLanguage;
				result.URLlimpia = fullUrl;
				result.academicHierarchy =
					academicHierarchy && academicHierarchy.toLocaleLowerCase();
				result.academicName = academicName && academicName.toLowerCase();
				result.academicModality = academicModality;
				result.academicECTs = academicECTs;
				result.academicStart = academicStart && academicStart.toLowerCase();
				result.academicProductID = academicProductID;
				result.academicRecognition = academicRecognition;
				result.academicQualification = academicQualification;
			}
			if (isIADE) {
				result.academicArea = academicArea;
			}
			if (isCEG) {
				result.pageType = "study";
				result.titulacion_formato = templateData?.dedication;
				result.titulacion_tipo_de_estudio =
					templateData?.programType?.[0]?.title;
				result.titulacion_nombre = templateData?.programTitle;
				result.titulacion_modalidad = templateData?.learningOption;
				result.titulacion_ECTs = templateData?.credits;
				result.titulacion_convocatoria = templateData?.start;
				result.titulacion_campus = "Centro de estudios Garrigues";
			}
			break;
		case "StudyList":
			if (isIPAM || isIADE || isIADEES) {
				result.pageType = "lista";
				result.pageLanguage = pageLanguage;
				result.URLlimpia = fullUrl;
			}
			if (isCEG) {
				result.pageType = "study list";
			}
			break;
		case "StaffDetail":
			if (isCEG) {
				result.pageType = "staff";
			}
			break;
		case "StaffList":
			if (isIPAM || isIADE || isIADEES) {
				result.pageType = "lista";
				result.pageLanguage = pageLanguage;
				result.URLlimpia = fullUrl;
			}
			if (isCEG) {
				result.pageType = "staff list";
			}
			break;
		case "BlogDetail":
			if (isCEG || isIADEES || isIADE || isIPAM) {
				result.pageType = "blog";
			}
			if (isIPAM || isIADE) {
				result.pageLanguage = pageLanguage;
				result.URLlimpia = fullUrl;
			}
			break;
		case "BlogList":
			if (isIADE || isIPAM) {
				result.pageType = "distribuicao blog";
			}
			if (isIPAM || isIADE || isIADEES) {
				result.pageLanguage = pageLanguage;
				result.URLlimpia = fullUrl;
			}
			if (isIADEES) {
				result.pageType = "distributiva blog";
			}
			if (isCEG) {
				result.pageType = "blog list";
			}
			break;
		case "AwardDetail":
			if (isCEG) {
				result.premio = templateData?.primerPremio;
				result.pageType = "award";
			}
			break;
		case "Error404":
			if (isCEG) {
				result.pageType = "error 404";
			}
			break;
		case "AdmissionRequestFiles":
			if (isCEG) {
				result.pageType = "admission request files";
			}
			break;
		case "LandingInfoPortugal":
			return pushDataLayerInfoLanding({
				formType: templateData?.formType?.modules[0]?.formType,
				study: templateData?.formType?.modules[0]?.queriedItems || [],
				currentLang: pageLanguage,
				fullUrl,
				window,
				...(isIADEES && { academicRecognition }),
				...(isIADEES && { academicQualification }),
			});
		case "LandingEventPortugal":
		case "LandingEasyPackPortugal":
			return pushDataLayerEventLanding({
				currentLang: pageLanguage,
				fullUrl,
			});
		default:
			break;
	}

	return result;
}

export { generateAutomaticDimensions };
